export const isBlendedLearningCourseType = (
  courseTypeCode: string | undefined,
) => courseTypeCode === 'blended'

export const isELearningAndResourceLibraryTraining = (
  courseTypeCode: string | undefined,
) => courseTypeCode === 'elearning' || courseTypeCode === 'resourcelibrary'

export const isNetworkEventCourseType = (courseTypeCode: string | undefined) =>
  courseTypeCode === 'networkevent'

export const courseTypeTranslationName = (courseTypeCode: string | undefined) =>
  courseTypeCode === 'elearning' ? 'webinar' : courseTypeCode

export const formattedCourseType = (courseTypeCode: string | undefined) =>
  courseTypeCode === 'online/offline' ? 'online_offline' : courseTypeCode

export const requestQuoteCourses = [
  'blended',
  'livewebinar',
  'virtualclassroom',
  'direct',
  'online/offline',
]
