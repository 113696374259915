// eslint-disable-next-line import/no-cycle, import/named
import {LevelLabel} from '.'
import {TrainingPageNode} from '../../../types/content-types'
import {TrainingSubscriptionAlgolia} from '../../../types/algolia'
import TrainingCard from '../../shared/TrainingCard'

type RelatedTrainingProps = {
  relatedTraining: TrainingPageNode | TrainingSubscriptionAlgolia
  difficultyLevelToLabel: LevelLabel
  courseTypeCode: string | undefined
  onClick: () => void
  price?: string
  isAlgoliaResult?: boolean
}

const RelatedTraining = ({
  relatedTraining,
  difficultyLevelToLabel,
  courseTypeCode,
  onClick,
  price,
  isAlgoliaResult,
}: RelatedTrainingProps) => {
  const field_difficulty_level = isAlgoliaResult
    ? (relatedTraining as TrainingSubscriptionAlgolia).field_difficulty_level
    : (relatedTraining as TrainingPageNode).attributes.field_difficulty_level
  const field_learning_pace = isAlgoliaResult
    ? (relatedTraining as TrainingSubscriptionAlgolia).field_learning_pace
    : (relatedTraining as TrainingPageNode).attributes.field_learning_pace
  const field_tr_new_date = isAlgoliaResult
    ? (relatedTraining as TrainingSubscriptionAlgolia).field_tr_new_date
    : (relatedTraining as TrainingPageNode).attributes.field_tr_new_date
  const field_is_new = field_tr_new_date
    ? Math.floor(new Date().getTime() / 1000) < field_tr_new_date
    : false
  const field_contain_exam = isAlgoliaResult
    ? (relatedTraining as TrainingSubscriptionAlgolia).field_contain_exam
    : (relatedTraining as TrainingPageNode).attributes.field_contain_exam
  const link = isAlgoliaResult
    ? {
        aliasTranslation: '',
        uri: {
          alias: (relatedTraining as TrainingSubscriptionAlgolia).url,
          uri: '',
          uri_uuid: '',
        },
      }
    : (relatedTraining as TrainingPageNode).attributes.path
  const relatedAlgoliaTraining = relatedTraining as TrainingSubscriptionAlgolia
  const relatedNodeTraining = relatedTraining as TrainingPageNode
  const imageUrl = isAlgoliaResult
    ? relatedAlgoliaTraining.field_image_url ||
      (relatedAlgoliaTraining.field_media_url
        ? `${process.env.NEXT_PUBLIC_API_URL}${relatedAlgoliaTraining.field_media_url}`
        : undefined)
    : relatedNodeTraining.attributes.field_image_url ||
      relatedNodeTraining.attributes.field_media_url
  const title = isAlgoliaResult
    ? relatedAlgoliaTraining.title
    : relatedNodeTraining.attributes.title

  return (
    <TrainingCard
      link={link}
      onClick={onClick}
      imageUrl={imageUrl}
      className={'d-flex align-items-stretch my-3 my-lg-0'}
      courseTypeCode={courseTypeCode}
      isNew={field_is_new}
      title={title}
      price={price}
      difficultyLevel={+(field_difficulty_level || 0)}
      difficultyLabel={difficultyLevelToLabel[field_difficulty_level || '0']}
      learningPace={field_learning_pace}
      containsExam={field_contain_exam}
    />
  )
}

export default RelatedTraining
