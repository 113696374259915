import {useTranslation} from 'next-i18next'
import {UIEvent, useCallback, useEffect, useRef, useState} from 'react'
import {Container} from 'react-bootstrap'
import striptags from 'striptags'
import debounce from '../../utils/debounce'
import type {LevelLabel} from '../content-types/TrainingPage'
import RelatedTraining from '../content-types/TrainingPage/RelatedTraining'
import type {Response} from '../../types/response'
import {BestSellersParagraph, Paragraph} from '../../types/paragraphs'
import GenericLink from '../shared/Link'
import {TrainingSubscriptionAlgolia} from '../../types/algolia'
import {difficultyLevelToLabelDataLayer} from '../../utils/datalayer'
import useGTM from '../../utils/hooks/useGTM'
import {formattedCourseType} from '../../utils/course-type-code'

declare const window: any

type BestSellersProps = {
  bestsellers?: TrainingSubscriptionAlgolia[] | null
  paragraph: Paragraph
  response: Response | null
}

const BestSellers = ({bestsellers, paragraph, response}: BestSellersProps) => {
  const {isLoaded: isGTMLoaded} = useGTM()
  const {t} = useTranslation('common')
  const fullParagraph = response?.included?.find(
    el => el.id === paragraph.id,
  ) as BestSellersParagraph | undefined
  const [backBtnDisabled, setBackBtnDisabled] = useState(true)
  const [forwardBtnDisabled, setForwardBtnDisabled] = useState(false)
  const relatedTrainingsRef = useRef<HTMLDivElement>(null)
  const relatedTrainingsWrapperRef = useRef<HTMLDivElement>(null)
  const relatedTrainingBtnBackwardRef = useRef<HTMLButtonElement>(null)
  const relatedTrainingBtnForwardRef = useRef<HTMLButtonElement>(null)
  const difficultyLevelToLabel: LevelLabel = {
    0: striptags(t('trainingpage_levelnotspecified')),
    1: striptags(t('trainingpage_levelstarter')),
    2: striptags(t('trainingpage_levelmedium')),
    3: striptags(t('trainingpage_levelexpert')),
  }

  const handleScrollRelatedTrainings = (e: UIEvent<HTMLDivElement>) => {
    if (
      (e.target as HTMLDivElement).scrollWidth <
      (e.target as HTMLDivElement).scrollLeft + 105 + window.innerWidth * 0.75
    ) {
      setForwardBtnDisabled(true)
    } else {
      setForwardBtnDisabled(false)
    }
    if ((e.target as HTMLDivElement).scrollLeft > 0) {
      setBackBtnDisabled(false)
    } else {
      setBackBtnDisabled(true)
    }
  }

  const handleScrollRelatedTrainingsDebounced = debounce(
    (e: UIEvent<HTMLDivElement>) => handleScrollRelatedTrainings(e),
    25,
  )

  const dataLayerPush = useCallback(
    (event: string, id?: string) => {
      if (isGTMLoaded) {
        const filterFn = (bs: TrainingSubscriptionAlgolia) =>
          id ? (bs.field_training_id || bs.field_subscription_id) === id : true

        const eCommerceItems = bestsellers
          ?.filter(filterFn)
          .map((bestseller, index) => {
            const {
              field_training_id,
              field_subscription_id,
              title,
              field_price_non_member,
              field_course_type_code,
              field_difficulty_level,
            } = bestseller

            return {
              item_id: field_training_id ?? field_subscription_id,
              item_name: title,
              price: Number(field_price_non_member).toFixed(2),
              quantity: 1,
              item_category: formattedCourseType(field_course_type_code),
              item_category2: difficultyLevelToLabelDataLayer(
                field_difficulty_level,
              ),
              index: index,
              item_list_id: 'bestsellers',
              item_list_name: 'Bestsellers',
            }
          })

        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({ecommerce: null}) // Clear the previous ecommerce object.
        window.dataLayer.push({
          event: event,
          ecommerce: {
            currency: striptags(t('currency_code')).trim(),
            items: eCommerceItems,
          },
        })
      }
    },
    [bestsellers, isGTMLoaded, t],
  )

  useEffect(() => {
    if (isGTMLoaded) {
      dataLayerPush('view_item_list')
    }
  }, [dataLayerPush, isGTMLoaded])

  return (
    <section
      className="section-box c-training__related"
      ref={relatedTrainingsRef}
    >
      <Container fluid className="p-0">
        <h2 className="text-center mb-4 d-none d-lg-block">
          {fullParagraph?.attributes.field_title}
        </h2>
        <div className="c-training__related-buttonWrapper d-flex d-lg-none px-4 justify-content-between align-items-center">
          <h2 className="text-center d-lg-none mb-0 c-training__related-title-mobile">
            {fullParagraph?.attributes.field_title}
          </h2>
          <div>
            <button
              type="button"
              disabled={backBtnDisabled}
              ref={relatedTrainingBtnBackwardRef}
              onClick={() => {
                relatedTrainingsWrapperRef.current!.scrollTo({
                  left:
                    (relatedTrainingsWrapperRef.current?.scrollLeft ?? 0) -
                    window.innerWidth * 0.75,
                  behavior: 'smooth',
                })
              }}
              aria-label="Scroll back"
            />
            <button
              type="button"
              className="ml-4"
              disabled={forwardBtnDisabled}
              ref={relatedTrainingBtnForwardRef}
              onClick={() => {
                relatedTrainingsWrapperRef.current!.scrollTo({
                  left:
                    (relatedTrainingsWrapperRef.current?.scrollLeft ?? 0) +
                    window.innerWidth * 0.75,
                  behavior: 'smooth',
                })
              }}
              aria-label="Scroll forward"
            />
          </div>
        </div>
        <div
          className="d-flex flex-row justify-content-center pb-2 overflow-auto c-training__related-wrapper px-4"
          ref={relatedTrainingsWrapperRef}
          onScroll={handleScrollRelatedTrainingsDebounced}
        >
          <div className="mw-100 d-flex flex-row justify-content-between mx-auto c-training__related-innerWrapper">
            {bestsellers?.map(bestseller => {
              return (
                <RelatedTraining
                  key={bestseller.objectID}
                  relatedTraining={bestseller}
                  difficultyLevelToLabel={difficultyLevelToLabel}
                  courseTypeCode={bestseller.field_course_type_code}
                  isAlgoliaResult
                  onClick={() =>
                    dataLayerPush('select_item', bestseller.field_course_type_code)
                  }
                  price={String(bestseller.field_price_non_member)}
                />
              )
            })}
          </div>
        </div>
        <div className="d-flex align-items-center mt-3">
          <GenericLink
            extraClass="btn btn--primary mx-auto"
            link={fullParagraph?.attributes.field_button}
          >
            {fullParagraph?.attributes.field_button?.title}
          </GenericLink>
        </div>
      </Container>
    </section>
  )
}

export default BestSellers
