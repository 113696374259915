import React from 'react'
import dynamic from 'next/dynamic'
import ContentBlockSection from './ContentBlockSection'
import InThePictureSection from './InThePicture'
import CategoryList from './CategoryList'
import {Paragraph} from '../../types/paragraphs'
import type {Response} from '../../types/response'
import BestSellers from './BestSellers'
import {TrainingSubscriptionAlgolia} from '../../types/algolia'

const CaseOverview = dynamic(() => import('./CaseOverview'))
const Text = dynamic(() => import('./Text'))
const TextAndMedia = dynamic(() => import('./TextAndMedia'))
const TextAndContact = dynamic(() => import('./TextAndContact'))
const Heading = dynamic(() => import('./Heading'))
const ArticleContentBlock = dynamic(() => import('./ArticleContentBlock'))
const PersonList = dynamic(() => import('./PersonList'))
const Quote = dynamic(() => import('./Quote'))
const DoubleQuote = dynamic(() => import('./DoubleQuote'))
const LinkBlock = dynamic(() => import('./LinkBlock'))
const TeaserList = dynamic(() => import('./TeaserList'))
const Spotlight = dynamic(() => import('./Spotlight'))
const HighlightedCategories = dynamic(() => import('./HighlightedCategories'))
const ClickableLogoList = dynamic(() => import('./ClickableLogoList'))
const FAQ = dynamic(() => import('./FAQ'))
const AnchorLink = dynamic(() => import('./AnchorLink'))
const CalendarBlock = dynamic(() => import('./CalendarBlock'))

const paragraphs: {
  [key: string]: () => React.ComponentType<Props>
} = {
  'paragraph--text': () => Text,
  'paragraph--text_and_media': () => TextAndMedia,
  'paragraph--text_and_contact': () => TextAndContact,
  'paragraph--content_block_section': () => ContentBlockSection,
  'paragraph--inthepicture': () => InThePictureSection,
  'paragraph--heading': () => Heading,
  'paragraph--article_content_block': () => ArticleContentBlock,
  'paragraph--person_list': () => PersonList,
  'paragraph--link_block': () => LinkBlock,
  'paragraph--quote': () => Quote,
  'paragraph--quote_quote': () => DoubleQuote,
  'paragraph--case_overview': () => CaseOverview,
  'paragraph--teaser_list': () => TeaserList,
  'paragraph--spotlight': () => Spotlight,
  'paragraph--category_list': () => CategoryList,
  'paragraph--highlighted_categories': () => HighlightedCategories,
  'paragraph--bestsellers': () => BestSellers,
  'paragraph--clickable_logo_list': () => ClickableLogoList,
  'paragraph--faq_block': () => FAQ,
  'paragraph--anchor': () => AnchorLink,
  'paragraph--calendar': () => CalendarBlock,
}

type Props = {
  paragraph: Paragraph
  casesAndFilters?: Response[] | null
  response: Response | null
  bestsellers?: TrainingSubscriptionAlgolia[] | null
}

const ParagraphMapper = ({
  paragraph,
  casesAndFilters,
  response,
  bestsellers,
}: Props) => {
  if (Object.prototype.hasOwnProperty.call(paragraphs, paragraph.type)) {
    const ParagraphComponent = paragraphs[paragraph.type]()
    return (
      <ParagraphComponent
        paragraph={paragraph}
        casesAndFilters={casesAndFilters}
        response={response}
        bestsellers={bestsellers}
      />
    )
  }
  return null
}

export default ParagraphMapper
