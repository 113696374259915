import React from 'react'
import Nav from 'react-bootstrap/Nav'
import {MenuLink} from '../../../types/content-types'
import {useTranslation} from 'next-i18next'

type Props = {
  socialMenuItems?: MenuLink[]
}

const SocialMediaNav: React.FC<Props> = ({socialMenuItems}: Props) => {
  const {t} = useTranslation()
  return (
    <Nav className="c-footer__doormat--socialMediaNavWrapper">
      <Nav.Link className="c-footer__doormat--navItem c-footer__doormat--navHeader">
        {t('doormat_follow_us')}
      </Nav.Link>
      <Nav className="c-footer__doormat--socialMediaNav">
        {socialMenuItems
          ?.filter(social => social.attributes.enabled)
          .sort((a, b) => a.attributes.weight! - b.attributes.weight!)
          .map(socialMenuItem => (
            <a
              key={socialMenuItem.id}
              className="nav-link c-footer__doormat--navItem"
              href={socialMenuItem.attributes.link.uri.uri}
              aria-label={socialMenuItem.attributes.field_social_type}
              target={socialMenuItem.attributes.link.options?.attributes.target}
            >
              <span
                className={`c-footer__doormat--socialMediaNav-icon c-footer__doormat--socialMediaNav-${socialMenuItem.attributes.field_social_type}`}
              />
            </a>
          ))}
      </Nav>
    </Nav>
  )
}

export default SocialMediaNav
