import type {Response} from '../../../types/response'
import {DrupalLink} from '../../../types/content-types'
import {Reference} from '../../../types/paragraphs'
import GenericLink from '../../shared/Link'
import MediaItem from '../../shared/MediaItem'
import RichText from '../../shared/RichText'

type Props = {
  data: Reference
  response: Response | null
}

const ReferenceItem = ({data, response}: Props) => {
  const tag = response?.included?.find(
    el => el.id === data.relationships.field_content_type?.data?.id,
  )
  let image
  let title
  let description
  let button: DrupalLink | undefined
  let buttonLabel: string | undefined
  if (data.type === 'paragraph--reference_item') {
    const referencedItem = response?.included?.find(
      el => el.id === data.relationships.field_reference?.data?.id,
    )
    if (!referencedItem) {
      return null
    }
    title = referencedItem.attributes.title
    image = response?.included?.find(
      el => el.id === referencedItem?.relationships.field_media?.data?.id,
    )
    description = referencedItem?.attributes.field_description
    button = {
      uri: {
        alias: '',
        uri: '',
        uri_uuid: '',
      },
      alias: referencedItem.attributes.path.alias,
      aliasTranslation: '',
    }
    buttonLabel = data.attributes.field_button_label
  } else {
    image = response?.included?.find(
      el => el.id === data.relationships.field_media?.data?.id,
    )
    title = data.attributes.field_title
    description = data.attributes.field_description
    button = data.attributes.field_button
  }

  return (
    <div className="c-reference-item">
      {image && (
        <div className="c-reference-item__image-container">
          <MediaItem
            item={image}
            response={response}
            wrapperClass="c-reference-item__image"
            objectFit="cover"
            imageClass="max-w-100 h-auto"
          />
          {tag && (
            <div className="c-reference-item__tag">{tag.attributes.name}</div>
          )}
        </div>
      )}

      <div className="c-reference-item__content">
        {title && <h4>{title}</h4>}
        {description && (
          <RichText
            extraClass="c-reference-item__description"
            html={description.processed}
          />
        )}

        {button && (
          <GenericLink extraClass="btn btn--white" link={button}>
            {button.title ?? buttonLabel}
          </GenericLink>
        )}
      </div>
    </div>
  )
}

export default ReferenceItem
