import {ReactNode} from 'react'
import Head from 'next/head'
import {v4 as uuidv4} from 'uuid'
import {useRouter} from 'next/router'
import Footer from './footer/Footer'
import Header from './header/Header'
import {Node} from '../../types/content-types'
import type {Response} from '../../types/response'

type Props = {
  children?: ReactNode
  mainMenuItems: Omit<Response, 'data' | 'meta' | 'links'> | null
  functionalNavItems: Omit<Response, 'data' | 'meta' | 'links'> | null
  doormatMenuItems: Omit<Response, 'data' | 'meta' | 'links'> | null
  socialMenuItems: Omit<Response, 'data' | 'meta' | 'links'> | null
  footerMenuItems: Omit<Response, 'data' | 'meta' | 'links'> | null
  page: Node | null
  siteSwitchMenuItems: Omit<Response, 'data' | 'meta' | 'links'> | null
  doormatButtonMenuItems: Omit<Response, 'data' | 'meta' | 'links'> | null
  languageSwitchMenuItems: Omit<Response, 'data' | 'meta' | 'links'> | null
}

const Layout = ({
  children,
  mainMenuItems,
  functionalNavItems,
  doormatMenuItems,
  socialMenuItems,
  footerMenuItems,
  siteSwitchMenuItems,
  doormatButtonMenuItems,
  languageSwitchMenuItems,
  page,
}: Props) => {
  const router = useRouter()
  const ogTitle = page?.attributes.metatag_normalized.find(
    el => el.attributes.property === 'og:title',
  )
  const ogDescription = page?.attributes.metatag_normalized.find(
    el => el.attributes.property === 'og:description',
  )
  return (
    <div>
      <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <link
          rel="icon"
          href={`${process.env.NEXT_PUBLIC_BASEPATH}/favicon.ico`}
        />
        {page?.attributes.metatag_normalized?.map(metatag => {
          const key = uuidv4()
          if (
            metatag.tag === 'link' &&
            metatag.attributes.rel === 'canonical'
          ) {
            const linkStartsWithLangCode = metatag.attributes.href?.startsWith(
              `/${page?.attributes.langcode}`,
            )
            let linkWithoutLangCode = metatag.attributes.href
            if (linkStartsWithLangCode) {
              linkWithoutLangCode = metatag.attributes.href?.replace(
                `/${page?.attributes.langcode}`,
                '',
              )
            }

            return (
              <link
                key={key}
                rel={metatag.attributes.rel}
                href={
                  page?.type === 'node--homepage'
                    ? `${process.env.NEXT_PUBLIC_EXTERNAL_DATA_URL}${process.env.NEXT_PUBLIC_BASEPATH}`
                    : `${process.env.NEXT_PUBLIC_EXTERNAL_DATA_URL}${process.env.NEXT_PUBLIC_BASEPATH}${linkWithoutLangCode}`
                }
              />
            )
          }
          if (
            metatag.tag === 'link' &&
            metatag.attributes.rel === 'alternate'
          ) {
            const basePathForTag =
              metatag.attributes.hreflang === router.locale
                ? process.env.NEXT_PUBLIC_BASEPATH
                : process.env.NEXT_PUBLIC_BASEPATH_ALTERNATE
            return (
              <link
                key={key}
                hrefLang={
                  page?.attributes.langcode === metatag.attributes.hreflang
                    ? 'x-default'
                    : metatag.attributes.hreflang
                }
                rel={metatag.attributes.rel}
                href={
                  page?.type === 'node--homepage'
                    ? `${
                        process.env.NEXT_PUBLIC_EXTERNAL_DATA_URL
                      }${basePathForTag}/${
                        metatag.attributes.href?.split('/')[1]
                      }`
                    : `${process.env.NEXT_PUBLIC_EXTERNAL_DATA_URL}${basePathForTag}${metatag.attributes.href}`
                }
              />
            )
          }
          if (metatag.attributes.name === 'title') {
            return <title key="title">{metatag.attributes.content}</title>
          }
          if (metatag.attributes.property === 'og:image') {
            return (
              <meta
                key={key}
                content={`${
                  metatag.attributes.content?.startsWith('https')
                    ? ''
                    : `${process.env.NEXT_PUBLIC_EXTERNAL_DATA_URL}${
                        process.env.NEXT_PUBLIC_BASEPATH ?? ''
                      }`
                }${metatag.attributes.content}`}
                property="og:image"
              />
            )
          }
          if (metatag.attributes.name === 'description') {
            return (
              <meta
                key={key}
                name="description"
                content={metatag.attributes.content}
              />
            )
          }
          return (
            <meta
              key={key}
              name={metatag.attributes.name || undefined}
              content={metatag.attributes.content}
              property={metatag.attributes.property}
            />
          )
        })}
        {ogTitle && ogDescription ? (
          <meta property="og:title" content={ogTitle.attributes.content} />
        ) : null}
      </Head>
      <Header
        mainMenuItems={mainMenuItems}
        functionalNavItems={functionalNavItems}
        siteSwitchMenuItems={siteSwitchMenuItems}
        languageSwitchMenuItems={languageSwitchMenuItems}
        urlTranslations={page?.attributes.path_translations}
        isTrainingPage={page?.type === 'node--training'}
      />

      <div>{children}</div>
      <Footer
        footerMenuItems={footerMenuItems}
        doormatMenuItems={doormatMenuItems}
        socialMenuItems={socialMenuItems}
        doormatButtonMenuItems={doormatButtonMenuItems}
      />
    </div>
  )
}

export default Layout
