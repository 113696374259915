import classNames from 'classnames'
import Link from 'next/link'
import GenericLink from '../../../shared/Link'
import MediaItem from '../../../shared/MediaItem'
import {ContentBlockData} from '../../../../types/paragraphs'
import type {Response} from '../../../../types/response'
import RichText from '../../../shared/RichText'

type Props = {
  data: ContentBlockData
  singleBlock?: boolean
  mediaPlacement: string
  response: Response | null
}

const ContentBlock = ({
  data,
  mediaPlacement,
  singleBlock = false,
  response,
}: Props) => {
  const mediaDocuments = response?.included?.filter(el =>
    data?.relationships?.field_pdf?.data?.find(doc => doc.id === el.id),
  )
  const mediaFiles = response?.included?.filter(el =>
    mediaDocuments?.find(
      doc => doc?.relationships?.field_media_file?.data?.id === el.id,
    ),
  )
  const contentBlockMedia = response?.included?.find(
    el => el.id === data.relationships.field_media?.data?.id,
  )
  const description = data.attributes.field_text?.processed
  const backgroundColor = data.attributes.field_content_block_background
  const alignment = data.attributes.field_alignment
  const iframe = data.attributes.field_iframe
  const button = data.attributes.field_button
  const buttonStyle = data.attributes.field_button_style
  const sparks = data.attributes.field_sparks
  const btnClass = classNames('btn', {
    'btn-link': buttonStyle === 'arrow',
    'btn-secondary': buttonStyle === 'secondary' && backgroundColor === 'none',
    'btn--red btn--red-contentblock':
      (!buttonStyle || buttonStyle === 'default') && backgroundColor === 'none',
    'btn--white': backgroundColor !== 'none',
  })

  const CustomHeading =
    `${data.attributes.field_heading_type_content}` as keyof JSX.IntrinsicElements

  let CustomSubHeading
  if (CustomHeading === 'h2') {
    CustomSubHeading = 'h3' as keyof JSX.IntrinsicElements
  } else if (CustomHeading === 'h3') {
    CustomSubHeading = 'h4' as keyof JSX.IntrinsicElements
  } else {
    CustomSubHeading = 'h5' as keyof JSX.IntrinsicElements
  }

  return (
    <div
      className={`c-content-block c-content-block--bg-${backgroundColor} c-content-block--align-${alignment} ${
        sparks ? 'c-content-block--sparks' : ''
      } ${singleBlock ? `c-content-block--media-align-${mediaPlacement}` : ''}`}
    >
      {contentBlockMedia && (
        <MediaItem
          response={response}
          item={contentBlockMedia}
          imageClass="max-w-100 h-auto"
          wrapperClass={
            contentBlockMedia.type === 'media--video'
              ? 'c-content-block__video'
              : 'c-content-block__image'
          }
        />
      )}
      <div className="c-content-block__text-wrapper">
        <CustomHeading>{data.attributes.field_title}</CustomHeading>
        <CustomSubHeading>{data.attributes.field_subtitle}</CustomSubHeading>
        {description && (
          <RichText
            extraClass="c-content-block__description"
            html={description}
          />
        )}
        {iframe && (
          <div
            className="c-content-block__iframe rich-text"
            dangerouslySetInnerHTML={{
              __html: iframe,
            }}
          />
        )}
        {mediaFiles ? (
          <div className="rich-text">
            <ul>
              {mediaFiles.map(file => (
                <li key={file?.id}>
                  <Link
                    href={`${process.env.NEXT_PUBLIC_EXTERNAL_DATA_URL}${
                      process.env.NEXT_PUBLIC_BASEPATH ?? ''
                    }${file?.attributes?.uri?.url ?? ''}`}
                    target="_blank"
                    className="pdf-link"
                    passHref
                  >
                    {file?.attributes?.filename}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        ) : null}
        {button && (
          <GenericLink extraClass={btnClass} link={button}>
            {button.title}
          </GenericLink>
        )}
      </div>
    </div>
  )
}

export default ContentBlock
