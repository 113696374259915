// eslint-disable-next-line import/prefer-default-export
export const difficultyLevelToLabelDataLayer = (
  field_difficulty_level?: string,
) => {
  if (!field_difficulty_level || field_difficulty_level === '0') {
    return 'Not specified'
  }
  if (field_difficulty_level === '1') {
    return 'Basic'
  }
  if (field_difficulty_level === '2') {
    return 'Medium'
  }
  if (field_difficulty_level === '3') {
    return 'Expert'
  }
  return ''
}
