import {useRouter} from 'next/router'

type RichTextProps = {
  html?: string
  extraClass?: string
}

const RichText = ({html, extraClass = ''}: RichTextProps) => {
  const router = useRouter()
  if (!html) {
    return null
  }

  const internalPageLinkRegex = new RegExp(`href="\/${router.locale}`, 'g')
  const internalPDFLinkRegex = new RegExp(/href="\/sites/, 'g')
  const internalPageLinkReplaceValue = `href="${router.basePath}`
  const internalPDFLinkReplaceValue = `href="${router.basePath}/sites`
  const parsedHtml = html
    .replace(internalPageLinkRegex, internalPageLinkReplaceValue)
    .replace(internalPDFLinkRegex, internalPDFLinkReplaceValue)

  return (
    <div
      className={`rich-text ${extraClass}`}
      dangerouslySetInnerHTML={{
        __html: parsedHtml,
      }}
    />
  )
}

export default RichText
