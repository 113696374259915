import {useTranslation} from 'next-i18next'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import striptags from 'striptags'
import type {Response} from '../../../types/response'
import {MenuLink} from '../../../types/content-types'
import Doormat from './Doormat'
import FooterNavigation from './FooterNavigation'

type Props = {
  footerMenuItems: Omit<Response, 'data' | 'meta' | 'links'> | null
  doormatMenuItems: Omit<Response, 'data' | 'meta' | 'links'> | null
  socialMenuItems: Omit<Response, 'data' | 'meta' | 'links'> | null
  doormatButtonMenuItems: Omit<Response, 'data' | 'meta' | 'links'> | null
}

const Footer: React.FC<Props> = ({
  footerMenuItems,
  doormatMenuItems,
  socialMenuItems,
  doormatButtonMenuItems,
}: Props) => {
  const currentYear = new Date().getFullYear()
  const footerMenuItemsFull = footerMenuItems?.included?.filter(
    el => el.type === 'menu_link_content--footer',
  ) as MenuLink[]
  const {t} = useTranslation()
  return (
    <footer className="c-footer">
      <Doormat
        doormatMenuItems={doormatMenuItems}
        socialMenuItems={socialMenuItems}
        doormatButtonMenuItems={doormatButtonMenuItems}
      />
      <div className="c-footer__container">
        <Container fluid>
          <Row>
            <Col md={{span: 6}} xl={{span: 2}}>
              <span className="c-footer__brand">
                {striptags(t('footer_copyright'))} {currentYear}
              </span>
            </Col>
            <Col md={{span: 12}} xl={{span: 10}}>
              <FooterNavigation footerMenuItems={footerMenuItemsFull} />
            </Col>
          </Row>
        </Container>
      </div>
    </footer>
  )
}

export default Footer
