import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import GenericLink from '../../shared/Link'
import SocialMediaNav from './SocialMediaNav'
import ButtonDoormatNavigation from './ButtonDoormatNavigation'
import type {Response} from '../../../types/response'
import {MenuLink} from '../../../types/content-types'

type Props = {
  doormatMenuItems: Omit<Response, 'data' | 'meta' | 'links'> | null
  doormatButtonMenuItems: Omit<Response, 'data' | 'meta' | 'links'> | null
  socialMenuItems: Omit<Response, 'data' | 'meta' | 'links'> | null
}

const Doormat: React.FC<Props> = ({
  doormatMenuItems,
  socialMenuItems,
  doormatButtonMenuItems,
}: Props) => {
  const doormatMenuItemsFull = doormatMenuItems?.included?.filter(
    el => el.type === 'menu_link_content--doormat',
  ) as MenuLink[] | undefined
  const doormatMenuItemsFiltered = doormatMenuItemsFull?.filter(
    el => el.attributes.enabled,
  )
  const topLevelDoormatMenuItems = doormatMenuItemsFiltered?.filter(
    el => !el.attributes.parent,
  )
  const socialMenuItemsFull = socialMenuItems?.included?.filter(
    el => el.type === 'menu_link_content--social',
  ) as MenuLink[] | undefined
  const doormatButtonMenuItemsFull = doormatButtonMenuItems?.included?.filter(
    el => el.type === 'menu_link_content--button-navigation-doormat',
  ) as MenuLink[] | undefined

  return (
    <div className="c-footer__doormat">
      <Container fluid>
        <Row className="c-footer__doormat--navWrapper">
          {topLevelDoormatMenuItems
            ?.sort((a, b) => a.attributes.weight! - b.attributes.weight!)
            .map(menuItem => {
              const enabledChildren = doormatMenuItemsFiltered?.filter(el =>
                el.attributes.parent?.includes(menuItem.id),
              )
              return (
                <Col key={menuItem.id}>
                  {enabledChildren
                    ?.sort(
                      (a, b) => a.attributes.weight! - b.attributes.weight!,
                    )
                    .map(navHeaderItem => {
                      const enabledGrandChildren =
                        doormatMenuItemsFiltered?.filter(el =>
                          el.attributes.parent?.includes(navHeaderItem.id),
                        )
                      return (
                        <div key={navHeaderItem.id}>
                          <GenericLink
                            extraClass="c-footer__doormat--navItem c-footer__doormat--navHeader"
                            link={navHeaderItem.attributes.link}
                          >
                            {navHeaderItem.attributes.title}
                          </GenericLink>
                          <div className="c-footer__doormat--navItem--childrenWrapper">
                            {enabledGrandChildren
                              ?.sort(
                                (a, b) =>
                                  a.attributes.weight! - b.attributes.weight!,
                              )
                              .map(navItem => (
                                <GenericLink
                                  extraClass="c-footer__doormat--navItem"
                                  key={navItem.id}
                                  link={navItem.attributes.link}
                                >
                                  {navItem.attributes.title}
                                </GenericLink>
                              ))}
                          </div>
                        </div>
                      )
                    })}
                </Col>
              )
            })}
          <Col>
            <ButtonDoormatNavigation
              doormatButtonMenuItems={doormatButtonMenuItemsFull}
            />
            <SocialMediaNav socialMenuItems={socialMenuItemsFull} />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Doormat
