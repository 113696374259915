import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import striptags from 'striptags'
import {useTranslation} from 'next-i18next'
import MediaItem from '../shared/MediaItem'
import GenericLink from '../shared/Link'
import {
  CategoryLink,
  CategoryListParagraph,
  Paragraph,
} from '../../types/paragraphs'
import type {Response} from '../../types/response'
import {CategoryNode} from '../../types/content-types'

type CategoryListProps = {
  paragraph: Paragraph
  response: Response | null
}

const CategoryList = ({paragraph, response}: CategoryListProps) => {
  const {t} = useTranslation()
  const paragraphFull = response?.included?.find(
    el => el.id === paragraph.id,
  ) as CategoryListParagraph | undefined
  if (!paragraphFull) {
    return null
  }
  const categories = response?.included?.filter(el =>
    paragraphFull.relationships.field_categories.data.find(
      cat => cat.id === el.id,
    ),
  ) as CategoryLink[]
  return (
    <section className="section-box c-category-list">
      <Container fluid>
        <Row>
          <Col>
            <h2 className="mb-4">{paragraphFull.attributes.field_title}</h2>
          </Col>
        </Row>
        <Row>
          {categories?.map(cat => {
            const categoryPage = response?.included?.find(
              el => el.id === cat.relationships.field_category_page.data?.id,
            ) as CategoryNode
            if (!categoryPage?.attributes) {
              return null
            }
            const media = response?.included?.find(
              el => el.id === categoryPage.relationships.field_icon?.data.id,
            )
            return (
              <Col xs={6} lg={4} key={cat.id}>
                <GenericLink
                  link={categoryPage.attributes.path}
                  extraClass="c-category-list__innerWrapper p-4 p-lg-3 d-flex flex-column flex-lg-row text-center text-lg-left align-items-center"
                >
                  <MediaItem
                    response={response}
                    layout="fixed"
                    width={60}
                    height={60}
                    item={media}
                  />
                  <div>
                    <h3 className="font-weight-bold">
                      {cat.attributes.field_label}
                    </h3>
                    {categoryPage.attributes.field_number_of_items ? (
                      // eslint-disable-next-line react/jsx-indent
                      <p className="mb-0 c-category-list__available">
                        {striptags(t('categorylist_trainingsavailable'))}:{' '}
                        {categoryPage.attributes.field_number_of_items}
                      </p>
                    ) : null}
                  </div>
                </GenericLink>
              </Col>
            )
          })}
        </Row>
      </Container>
    </section>
  )
}

export default CategoryList
