import {useState} from 'react'
import {v4 as uuidv4} from 'uuid'
import GenericLink from '../../shared/Link'
import type {Response} from '../../../types/response'
import {DrupalLink, MenuLink} from '../../../types/content-types'

type Props = {
  siteSwitchMenuItems?: Omit<Response, 'data' | 'meta' | 'links'> | null
  urlTranslations?: DrupalLink[]
  isTrainingPage: boolean
}

const TopNavigation = ({siteSwitchMenuItems}: Props) => {
  const [activeState, setActiveState] = useState(0)
  const siteSwitchMenuItemsFull = siteSwitchMenuItems?.included?.filter(
    el => el.type === 'menu_link_content--site-switch',
  ) as MenuLink[]

  return (
    <div className="c-top-navigation d-flex justify-content-between align-items-center">
      <div className="ctn-audience-wrapper">
        {siteSwitchMenuItemsFull
          ?.filter(el => el.attributes.enabled)
          .sort((a, b) => a.attributes.weight! - b.attributes.weight!)
          .map((item, index) => {
            const id = uuidv4()
            return (
              <GenericLink
                extraClass={`ctn-audience-link ${
                  index === activeState ? 'active' : ''
                }`}
                link={item.attributes.link}
                key={id}
                onClick={() => setActiveState(index)}
              >
                {item.attributes.title}
              </GenericLink>
            )
          })}
      </div>
    </div>
  )
}

export default TopNavigation
