import {useCallback} from 'react'
import {useTranslation} from 'next-i18next'
import Image from 'next/image'
import GenericLink from './Link'
import {DrupalLink} from '../../types/content-types'
import striptags from 'striptags'
import arrowRightIcon from '../../public/arrow-right.svg'
import sanitizeImageUrl from '../../utils/sanitize-image-url'
import {formattedCourseType} from '../../utils/course-type-code'

type Props = {
  link?: DrupalLink | undefined
  onClick?: () => void
  imageUrl?: string
  className?: string
  courseTypeCode: string | undefined
  isNew?: boolean
  title: string
  price?: string
  difficultyLevel: number
  difficultyLabel?: string
  containsExam?: boolean
  learningPace?: string
}

// Parameters
/**
 * @param link - The link to redirect to when the card is clicked
 * @param onClick - Callback fired when the card is clicked
 * @param imageUrl - Background image
 * @param className - CSS classes passed to the card's parent element
 * @param courseType - Value in utils/course-type >> courseTypes
 * @param courseTypeLabel - The course label tag displayed if this is truthy
 * @param isNew - The `NEW` tag is displayed if this is true
 * @param title - Card's title
 * @param price - The price of the current course
 * @param difficultyLevel - The difficulty level in the footer (0-3)
 * @param difficultyLabel - The difficulty label in the footer
 * @param containsExam - If true, `Certificate` is displayed in the footer
 * @param learningPace - Duration of the course
 */
const TrainingCard = ({
  link,
  onClick,
  imageUrl,
  className = '',
  courseTypeCode,
  isNew = false,
  title,
  price,
  difficultyLevel = 0,
  difficultyLabel,
  containsExam = false,
  learningPace,
}: Props) => {
  const {t} = useTranslation()
  const courseType = formattedCourseType(courseTypeCode)

  const renderHeader = useCallback(() => {
    const fallbackImageUrl = sanitizeImageUrl(t('training_bgfallbackurl'))
    const bgImage = `url('${imageUrl || fallbackImageUrl}')`

    return (
      <div
        className={`w-100 d-flex flex-row align-items-start align-content-start flex-wrap overflow-hidden c-training-card__header`}
        style={{
          backgroundImage: bgImage,
        }}
      >
        {courseTypeCode ? (
          <div className="d-flex flex-row align-items-center c-training-card__header--category">
            <i className={`icon ${courseType}Icon`} />
            <span>{striptags(t(`course_type_${courseType}`))}</span>
          </div>
        ) : null}
        {isNew ? (
          <div className="ml-auto c-training-card__header--new-tag">
            {striptags(t('trainingsearch_new'))}
          </div>
        ) : null}
      </div>
    )
  }, [courseType, courseTypeCode, imageUrl, isNew, t])

  const renderBody = useCallback(() => {
    let formattedPrice = null

    if (price) {
      const priceWithCurrency = `${striptags(
        t('currency_format'),
      )} ${Math.round(Number(price))}`

      if (courseTypeCode === 'subscription') {
        formattedPrice = `${priceWithCurrency} / ${t(
          'booking_priceoverview_yearlylabel',
        ).trim()}`
      } else {
        formattedPrice = priceWithCurrency
      }
    }

    return (
      <div className="d-flex flex-column justify-content-between align-items-start flex-grow-1 c-training-card__body">
        <h3 className="font-weight-bold m-0 c-training-card__body--title">
          {title}
        </h3>
        <div className="w-100 mw-100 d-flex flex-row justify-content-between align-items-center">
          {formattedPrice ? (
            <span className="font-weight-bold text-sm c-training-card__body--price">
              {formattedPrice}
            </span>
          ) : null}
          <span className="d-flex justify-content-center align-items-center c-training-card__body--arrow">
            <Image alt="network icon" width={16} src={arrowRightIcon} />
          </span>
        </div>
      </div>
    )
  }, [courseTypeCode, price, t, title])

  const renderFooter = useCallback(() => {
    return (
      <div className="w-100 d-flex align-items-start mt-auto c-training-card__footer">
        {difficultyLevel > 0 ? (
          <div className="d-flex align-items-center flex-wrap c-training-card__footer--difficulty-level-wrapper">
            <div className="d-flex flex-row c-training-card__footer--difficulty-level-icon-wrapper">
              {Array(3)
                .fill(0)
                .map((_dot, i) => (
                  <span
                    key={String(i)}
                    className={`c-training-card__footer--difficulty-level-icon ${
                      i + 1 <= difficultyLevel ? 'filled' : ''
                    }`}
                  />
                ))}
            </div>
            <div className="c-training-card__footer--difficulty-level-label">
              {difficultyLabel}
            </div>
          </div>
        ) : null}
        {containsExam ? (
          <div className="d-flex align-items-center flex-wrap c-training-card__footer--exam-wrapper">
            <i className="icon c-training-card__footer--exam-icon" />
            <div className="c-training-card__footer--exam">
              {striptags(t('trainingsearch_certificate'))}
            </div>
          </div>
        ) : null}
        {learningPace ? (
          <div className="d-flex align-items-center flex-wrap ml-auto c-training-card__footer--learning-pace-wrapper">
            <i className="icon c-training-card__footer--learning-pace-icon" />
            <div className="c-training-card__footer--learning-pace">
              {learningPace}
            </div>
          </div>
        ) : null}
      </div>
    )
  }, [containsExam, difficultyLabel, difficultyLevel, learningPace, t])

  return (
    <GenericLink
      link={link}
      onClick={onClick}
      extraClass={`c-training-card d-flex flex-column w-100 ${className}`}
    >
      {renderHeader()}
      {renderBody()}
      {renderFooter()}
    </GenericLink>
  )
}

export default TrainingCard
