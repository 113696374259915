import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import ContentBlock from './content-block/ContentBlock'
import {
  ContentBlockData,
  ContentBlockSectionParagraph,
  Paragraph,
} from '../../../types/paragraphs'
import type {Response} from '../../../types/response'

type Props = {
  paragraph: Paragraph
  response: Response | null
}

const ContentBlockSection = ({paragraph, response}: Props) => {
  const paragraphFull = response?.included?.find(
    el => el.id === paragraph.id,
  ) as ContentBlockSectionParagraph | undefined
  if (!paragraphFull) {
    return null
  }
  const contentBlocks = response?.included?.filter(el =>
    paragraphFull.relationships.field_content_block?.data?.find(
      cb => cb.id === el.id,
    ),
  ) as ContentBlockData[]
  const background = paragraphFull.attributes.field_content_block_section_back
  const sparks = paragraphFull.attributes.field_sparks

  const colSpan: Record<number, number> = {
    3: 4,
    2: 6,
    1: 12,
  }

  return (
    <section
      className={`c-content-block-section section--bg-${background} ${
        sparks ? 'section--sparks' : ''
      }`}
    >
      <Container fluid>
        <Row>
          {contentBlocks.map(contentBlock => (
            <Col
              lg={{span: colSpan[contentBlocks.length]}}
              key={contentBlock.id}
            >
              <ContentBlock
                mediaPlacement={paragraphFull.attributes.field_media_placement}
                singleBlock={contentBlocks.length === 1}
                data={contentBlock}
                response={response}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  )
}

export default ContentBlockSection
